import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { I18nProps } from '../api/i18n/i18n'
import LinkI18n from './link-i18n'

const HeaderI18n = (props: I18nProps) => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(
        relativePath: { eq: "mark-stosberg-AGIYSE6-WgE-unsplash.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 90
            formats: [AUTO, WEBP, AVIF]
            breakpoints: [400, 750, 1080, 1366, 1920, 2560]
            placeholder: BLURRED
          )
        }
      }
    }
  `)
  const bannerImage = getImage(data.bannerImage)

  return (
    <header className="l-header">
      <div className="l-banner-image">
        <GatsbyImage
          className="banner-image"
          image={bannerImage}
          alt="Colorful chalk sticks — free license by Mark Stosberg from Unsplash.com"
        />
      </div>
      <div className="l-banner-overlay"></div>
      <div className="l-banner-content">
        <div className="l-constrained text-light text-shadow">
          <h1 id="page-title" className="page-title">
            <Trans i18nKey="header.opening" t={t}>
              Imagine a world where...
            </Trans>
          </h1>
          <p className="l-constrained-tighter text-light page-lead">
            <ul>
              <li>Everyone has equitable access to all public data.</li>
              <li>
                All open data providers and custodians, always provide explicit,
                comprehensive and uptodate Data Quality Scores about their data.
              </li>
              <li>
                All data (public or not) is always rightfully and lawfully
                collected.
              </li>
            </ul>
          </p>
          <p>
            <LinkI18n
              to="/charts/"
              locale={props.locale}
              className="btn btn-outline-light btn-large"
            >
              <Trans i18nKey="common.cta.start-button" t={t}>
                START HERE
              </Trans>
            </LinkI18n>
          </p>
          {/*
          <p className="image-caption">
            Photo by {` `}
            <a
              href="https://unsplash.com/"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Mark Stosberg
            </a>
          </p> */}
        </div>
      </div>
    </header>
  )
}
export default HeaderI18n
