import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useLocale from '../hooks/use-locale'
import Layout from '../components/layout'
import Seo from '../components/seo'
import HeaderI18n from '../components/header-i18n'

const IndexPage = ({ pageContext }) => {
  const { t } = useTranslation()
  const { defaultLocale, locale } = useLocale()
  //console.log("----- pageContext: ", pageContext)

  return (
    <Layout>
      <Seo title="Home" />
      <HeaderI18n locale={locale()} defaultLocale={defaultLocale()} />
      <main className="l-main">
        <div className="l-constrained text-highlight">
          <h1>
            <Trans i18nKey="index.wip" t={t}>
              We Are Building Something Great...
            </Trans>
          </h1>
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage
